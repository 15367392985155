import React from "react";
import web3 from "./web3";
import keensightContract from "./keensight_mainnet";

class App extends React.Component {
  state = {
    price: 0.042,
    accounts: null,
    numberToMint: 1,
    numberToPreMint: 1,
    message: "",
    nftsOwned: 0,
    totalSupply: 0,
    spotsRemainingWL: 0,
    remainingPreMint: 0,
    remainingPublicMint: 0,
    preMintPaused: true,
    publicMintPaused: true,
    acceptedTerms: true
  };

  async componentDidMount() {
    try{
        if(this.state.accounts && this.state.accounts[0]){
            await this.updatePageStatus();
        }
    }catch (e) {
        console.log(e);
    }
  }

    checkPublicMintPaused = async () => {
        try{
            let paused = await keensightContract.methods.paused().call();
            this.setState({publicMintPaused: paused});
            console.log("public mint paused? : ", paused);
        }catch (e) {
            console.log(e);
        }
    }

    checkPreMintPaused = async () => {
        try{
            let paused = await keensightContract.methods.preMintPaused().call();
            this.setState({preMintPaused: paused});
            console.log("premint paused? : ", paused);
        }catch (e) {
            console.log(e);
        }
    }

    checkRemainingPublicMint = async () => {
        try{
            let numMinted = await keensightContract.methods.publicMinted(this.state.accounts[0]).call();
            let remaining = 10 - parseInt(numMinted);
            this.setState({remainingPublicMint: remaining});
            this.setState({numberToMint: (remaining > 10) ? 10 : remaining});
            console.log("your remaining public mint", remaining);
        }catch (e) {
            console.log(e);
        }
    }

    checkRemainingPreMint = async () => {
        try{
            let numPreMinted = await keensightContract.methods.preMinted(this.state.accounts[0]).call();
            let remaining = 1 - parseInt(numPreMinted);
            this.setState({remainingPreMint: remaining});
            this.setState({numberToPreMint: (remaining > 1) ? 1 : remaining});
            console.log("your remaining pre-mint", remaining);
        }catch (e) {
            console.log(e);
        }
    }

  updatePageStatus = async () => {
      try{
          await this.checkPreMintPaused();
          await this.checkPublicMintPaused();
          await this.getNFTs();
          await this.getSupply();
          await this.checkRemainingPublicMint();
          await this.checkRemainingPreMint();
      }catch (e) {
          console.log(e);
      }
  }

    choose = async (_num) => {
      try{
          console.log(_num);
          this.setState({numberToMint: _num});
      }catch (e) {
        console.log(e);
      }
    }

    onConnect = async () => {
        try{
            if(!window.ethereum){
                alert('you must run this application in a browser with the metamask extensions installed');
                return;
            }
            await window.ethereum.request({ method: "eth_requestAccounts" });
            let accounts = await web3.eth.getAccounts();
            this.setState({accounts: accounts});
            await this.updatePageStatus();
        }catch (e) {
            console.log(e);
        }
    }

    onChangeTermsAccepted = async (event) => {
        try{
            this.setState({acceptedTerms: event.currentTarget.checked});
            if(event.currentTarget.checked){
                this.setState({ message: "" });
            }else{
                this.setState({ message: "you must accept the terms and conditions to proceed" });
            }
        }catch (e) {
            console.log(e);
        }
    }

  onPublicMint = async (event) => {
    event.preventDefault();
    try{
        this.setState({ message: "Waiting on success of Mint..." });

        const pay = this.state.numberToMint * this.state.price;
        console.log(pay);
        const mint = await keensightContract.methods.mint(this.state.numberToMint).send({
            from: this.state.accounts[0],
            value: web3.utils.toWei(pay.toString(), "ether"),
        });
        if(mint){
            console.log(mint);
            this.setState({ message: "Complete!" });
            await this.updatePageStatus();
        }else{
            this.setState({ message: "Something went wrong..." });
        }
    }catch (e) {
        console.log(e);
        if(e.message){
            this.setState({ message: e.message });
        }
    }
  };

    getNFTs = async () => {
        try{
            const nftsOwned = await keensightContract.methods.balanceOf(this.state.accounts[0]).call();
            console.log("NFTs owned: " + nftsOwned);
            this.setState({nftsOwned: nftsOwned});
        } catch (e) {
            console.log(e);
        }
    }

    getSupply = async () => {
        try{
            const totalSupply = await keensightContract.methods.totalSupply().call();
            console.log("totalSupply: " + totalSupply);
            this.setState({totalSupply: totalSupply});
        } catch (e) {
            console.log(e);
        }
    }

    increaseMint = async () => {
        try{
            let current = this.state.numberToMint;
            current ++;
            if(current >= this.state.remainingPublicMint){
                current = this.state.remainingPublicMint;
            }
            this.setState({numberToMint: current});
        }catch (e) {
            console.log(e);
        }
    }

    decreaseMint = async () => {
        try{
            let current = this.state.numberToMint;
            current --;
            if(current <= 1){
                current = 1;
            }
            this.setState({numberToMint: current});
        }catch (e) {
            console.log(e);
        }
    }

  render() {
    return (
        <div className="noselect">
            <div className="keensight-container">
                <div className="keensight-box">
                    <div className="keensight-phrase-container">
                        <img src="/keensight_logo_1.png" alt="phrase"/>
                    </div>
                    {!this.state.accounts &&
                        <div>
                            <div>
                                <button className="green-bg button" onClick={this.onConnect}>connect</button>
                            </div>
                        </div>
                    }
                    {this.state.accounts && this.state.accounts[0] && this.state.publicMintPaused === true &&
                        <div>
                            <button className="green-bg button">Mint has ended...</button>
                        </div>
                    }
                    {this.state.accounts && this.state.accounts[0] && this.state.publicMintPaused === false &&
                        <div>
                            <div className="custom-number-input">
                                <div className="custom-increment left" onClick={this.decreaseMint}>-</div>
                                <div className="custom-number">{this.state.numberToMint}</div>
                                <div className="custom-increment right" onClick={this.increaseMint}>+</div>
                            </div>
                            <button className="green-bg button" disabled={this.state.remainingPublicMint < 1} onClick={this.onPublicMint}>mint {this.state.numberToMint}</button>
                        </div>
                    }
                    {this.state.message.length > 0 &&
                        <div className="message">{this.state.message}</div>
                    }
                </div>
                <footer>
                    <div className="keensight-link">
                        <a className="green-text" href="https://keensight.xyz/terms-and-conditions/" target="_blank" rel="noreferrer">CLICK HERE for Terms and Conditions</a>
                    </div>
                    <div></div>
                    <div className="message">
                        <label>by minting, you accept terms and conditions</label>
                        {/*<input type="checkbox" className="green-bg button" onChange={this.onChangeTermsAccepted}></input>*/}
                    </div>
                </footer>
            </div>
        </div>
    );
  }
}
export default App;
